import React from 'react'

export function Test() {
  return (
      <div>
        This is the test page
      </div>
  )
}

export default Test